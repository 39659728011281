<template>
  <div>
    <b-card :title="titulo">
      <b-card-text>
        <b-form>
          <validation-observer ref="form">
            <b-row>
              <b-col cols="12" md="6" xl="6">
                <b-form-group
                  label="Producto:"
                  label-for="producto"
                  label-cols="12"
                  label-cols-lg="2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Producto"
                    rules="required"
                  >
                    <v-select
                      id="producto"
                      v-model="form.productoId"
                      label="descripcion"
                      class="style-chooser select-size-sm"
                      placeholder="Buscar producto"
                      :filterable="true"
                      :clearable="false"
                      :options="productos"
                      :key="form.productoId"
                      :reduce="(opcion) => opcion.id"
                    >
                      <template #no-options="{ search }">
                        <span v-if="search">
                          Lo siento, no hay opciones de coincidencia
                        </span>
                        <span v-else>Digite para buscar</span>
                      </template>
                    </v-select>
                    <small class="text-danger text-small">
                      {{ errors[0] }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="6"
                xl="6"
                class="mt-1 mt-md-0 pt-personalizado"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-danger"
                  size="sm"
                  @click="restablecer"
                >
                  <span class="d-none d-md-block">
                    <feather-icon icon="RotateCwIcon" class="mr-50" />
                    <span class="align-middle">RESTABLECER</span>
                  </span>
                  <feather-icon
                    icon="RotateCwIcon"
                    class="d-md-none"
                    size="14"
                  />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  size="sm"
                  class="ml-1"
                  @click="validar(false)"
                >
                  <feather-icon icon="SearchIcon" class="mr-50" />
                  <span class="align-middle">CONSULTAR EN PANTALLA</span>
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-secondary"
                  size="sm"
                  class="ml-1"
                  @click="validar(true)"
                >
                  <span class="d-none d-md-block">
                    <feather-icon icon="FileIcon" class="mr-50" />
                    <span class="align-middle">VISUALIZAR EN PDF</span>
                  </span>
                  <feather-icon icon="FileIcon" class="d-md-none" size="14" />
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-form>
      </b-card-text>

      <b-card-text>
        <component
          :is="componenteListado"
          ref="listado"
          :items="items"
          :loading="loading"
        ></component>
      </b-card-text>
    </b-card>

    <ModalMostrarPdf
      :titulo="`${titulo} del ${this.form.desde} al ${this.form.hasta}`"
      :pdf="pdf"
      :pdfBase64="pdfBase64"
      :mostrarModal="mostarModal"
      @cerrarModal="cerrarModal"
    />
  </div>
</template>
    
<script>
import Ripple from "vue-ripple-directive";
import ReportesMixin from "../../mixins/ReportesMixin";
import ListadoKardex from "../views/ListadoKardex.vue";
import vSelect from "vue-select";
import ProductoServices from "@/modules/administracion/producto/services";

export default {
  name: "SedeHastaDesdeLayout",
  mixins: [ReportesMixin],
  props: {
    servicioGetReporte: {
      type: Function,
      required: true,
    },
    componenteListado: {
      type: String,
      required: true,
      validator: (value) => {
        return ["ListadoKardex"].includes(value);
      },
    },
  },
  components: {
    ListadoKardex,
    vSelect,
  },
  directives: {
    ripple: Ripple,
  },
  data() {
    return {
      form: {
        productoId: null,
      },
      productos: [],
    };
  },
  methods: {
    restablecer() {
      this.form.productoId = null;
      this.items = [];
    },
    async listarProductos() {
      try {
        const response = await ProductoServices.getProductosActivos(
          this.mixing.sede_id,
          0
        );
        this.productos = response.data;
        console.log(this.productos);
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.listarProductos();
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/reportes.scss";
</style>
    
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.text-small {
  font-size: 11px;
}

@media (min-width: 1500px) {
  .text-small {
    font-size: 12px;
  }
}
</style>
    