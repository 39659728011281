<template>
  <SedeProducto
    titulo="Reportes de Inventario - Kardex"
    :servicioGetReporte="servicioGetReporte"
    :servicioGetReportePdf="servicioGetReportePdf"
    :componenteListado="componenteListado"
  />
</template>
  
<script>
import SedeProducto from "../components/SedeProducto.vue";
import ReporteInventarioService from "../services";

export default {
  name: "ReporteInventarioMovimientoGeneralUsuarioLayout",
  components: {
    SedeProducto,
  },
  data() {
    return {
      componenteListado: "ListadoKardex",
    };
  },
  methods: {
    servicioGetReporte: ReporteInventarioService.getReporteInventarioKardex,
    servicioGetReportePdf:
      ReporteInventarioService.getReporteInventarioKardexPdf,
  },
};
</script>
  